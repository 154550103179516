import React, { useState, useEffect } from "react";
import "../Style/Header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import Header from "../Pages/Header";

const iconGames1 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s1.png";
const iconGames2 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s2.png";
const iconGames3 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s3.png";
const iconGames4 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s4.png";
const iconGames5 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s5.png";
const iconGames6 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s6.png";
const iconGames7 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s7.png";
const iconGames8 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s8.png";
const iconGames9 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s9.png";
const iconGames10 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s10.png";
const iconGames11 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s11.png";
const iconGames12 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s12.png";
const iconGames13 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s13.png";
const iconGames14 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s14.png";
const iconGames15 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s15.png";
const iconGames16 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s16.png";
const iconGames17 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s17.png";
const iconGames18 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s18.png";
const iconGames19 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s19.png";
const iconGames20 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s20.png";
const iconGames21 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s21.png";
const iconGames22 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s22.png";
const iconGames23 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s23.png";
const iconGames24 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s24.png";
const iconGames25 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s25.png";
const iconGames26 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s26.png";
const iconGames27 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/s27.png";

const iconGamec1 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c1.png";
const iconGamec2 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c2.png";
const iconGamec3 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c3.png";
const iconGamec4 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c4.png";
const iconGamec5 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c5.png";
const iconGamec6 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c6.png";
const iconGamec7 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c7.png";
const iconGamec8 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c8.png";
const iconGamec9 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c9.png";
const iconGamec10 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c10.png";
const iconGamec11 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c11.png";
const iconGamec12 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c12.png";
const iconGamec13 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c13.png";
const iconGamec14 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c14.png";
const iconGamec15 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c15.png";
const iconGamec16 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c16.png";
const iconGamec17 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c17.png";
const iconGamec18 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c18.png";
const iconGamec19 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c19.png";
const iconGamec20 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/c20.png";

const iconGamesp1 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/sp1.png";
const iconGamesp2 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/sp2.png";
const iconGamesp3 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/sp3.png";
const iconGamesp4 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/sp4.png";
const iconGamesp5 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/sp5.png";
const iconGamesp6 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/sp6.png";
const iconGamesp7 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/sp7.png";
const iconGamesp8 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/sp8.png";
const iconGamesp9 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/sp9.png";

const iconGamel1 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/l1.png";
const iconGamel2 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/l2.png";
const iconGamel3 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/l3.png";
const iconGamel4 = process.env.PUBLIC_URL + "https://fs.cdnxn.com/landing-sbo55/Images/icon-games/l4.png";


export default function Home() {
  const [linkLogin, setLinkLogin] = useState(
    "https://gamev2.sbobet55.bet/login"
  );

  const [selectedCategory, setSelectedCategory] = useState("game");
  const [displayedGames, setDisplayedGames] = useState([]);
  const gameCategories = ["game", "sport", "casino", "slot", "lotto"];

  const gameImages = {
    game: [iconGames1, iconGames2, iconGames3, iconGames4, iconGames5, iconGames6, iconGames7, iconGames8, iconGamec1, iconGamec2, iconGamec3, iconGamec4, iconGamec5, iconGamec6, iconGamec7, iconGamec8, iconGamesp1, iconGamesp2, iconGamesp3, iconGamesp4],
    slot: [iconGames1, iconGames2, iconGames3, iconGames4, iconGames5, iconGames6, iconGames7, iconGames8, iconGames9, iconGames10, iconGames11, iconGames12, iconGames13, iconGames14, iconGames15, iconGames16, iconGames17, iconGames18, iconGames19, iconGames20, iconGames21, iconGames22, iconGames23, iconGames24, iconGames25, iconGames26, iconGames27],
    lotto: [iconGamel1, iconGamel2, iconGamel3, iconGamel4],
    casino: [iconGamec1, iconGamec2, iconGamec3, iconGamec4, iconGamec5, iconGamec6, iconGamec7, iconGamec8, iconGamec9, iconGamec10, iconGamec11, iconGamec12, iconGamec13, iconGamec14, iconGamec15, iconGamec16, iconGamec17, iconGamec18, iconGamec19, iconGamec20],
    sport: [iconGamesp1, iconGamesp2, iconGamesp3, iconGamesp4, iconGamesp5, iconGamesp6, iconGamesp7, iconGamesp8, iconGamesp9],
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 430) {
        setDisplayedGames(gameImages[selectedCategory].slice(0, 9));
      } else {
        setDisplayedGames(gameImages[selectedCategory]);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [selectedCategory]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedCategory((prevCategory) => {
        const currentIndex = gameCategories.indexOf(prevCategory);
        const nextIndex = (currentIndex + 1) % gameCategories.length;
        return gameCategories[nextIndex];
      });
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div>
        <Header />
        <div className="home">
          <div className="home-section">
            <div id="section-1" className="section-1">
              <div className="tital-bg">
                <img src="https://fs.cdnxn.com/landing-sbo55/Images/tital-bg-left.png" className="tital-bg-left" alt="tital bg" />
                <span className="text-tital-bg text-tital-font1">WELCOME TO</span>
                <span className="text-tital-bg text-tital-font2">SBO55</span>
                <img src="https://fs.cdnxn.com/landing-sbo55/Images/tital-bg-right.png" className="tital-bg-right" alt="tital bg" />
              </div>
              <div className="bg-menu-flex">
                <div className="menu-flex container-section">
                  <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-tab1.png" alt="menu" />
                  <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-tab2.png" alt="menu" />
                  <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-tab3.png" alt="menu" />
                  <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-tab4.png" alt="menu" />
                </div>
                <div className="menu-flex-mini">
                  <Splide
                    className="custom-slider"
                    options={{
                      rewind: true,
                      gap: "10px",
                      type: "loop",
                      autoplay: true,
                      interval: 3000,
                      arrows: false,
                      pauseOnHover: false,
                      perPage: 3,
                      focus: 'left',
                    }}
                  >
                    <SplideSlide className="custom-slider">
                      <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-tab1.png" alt="" className="w-100" />
                    </SplideSlide>
                    <SplideSlide className="custom-slider">
                      <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-tab2.png" alt="" className="w-100" />
                    </SplideSlide>
                    <SplideSlide className="custom-slider">
                      <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-tab3.png" alt="" className="w-100" />
                    </SplideSlide>
                    <SplideSlide className="custom-slider">
                      <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-tab4.png" alt="" className="w-100" />
                    </SplideSlide>
                  </Splide>


                </div>
              </div>
            </div>
            <div className="section-2">
              <div className="section-2-box container-section">
                <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-2-img1.png" className="section-2-img1" alt="img1" />
                <div className="section-2-box-text">
                  <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-2-img1-mb.png" className="section-2-img1-mb" alt="img1" />
                  <div className="box-text-section-2">
                    <span className="text-tital-section-2">ทำไมต้อง SBO55 ?</span><br /><br />
                    <span>เว็บไซต์ของเราเป็นผู้นำในวงการเว็บพนันออนไลน์ที่มาพร้อมกับเทคโนโลยีที่ทันสมัยที่สุด
                      ตอบสนองทุกความต้องการของผู้เล่นได้อย่างลงตัว ด้วยระบบการทำงานที่รวดเร็วและเสถียร
                      ทำให้คุณมั่นใจได้ว่าทุกการเดิมพันจะเป็นไปอย่างราบรื่น เรามีความมั่นคงทางการเงิน
                      การันตีการจ่ายเงินจริงทุกบิล ไม่ว่าจะเป็นยอดเล็กหรือใหญ่ เราคือรายใหญ่ที่คนไว้วางใจมากที่สุด
                      พร้อมกับการรวบรวมค่ายเกมส์ชั้นนำมากมายจากทั่วทุกมุมโลก ให้คุณได้เลือกเล่นอย่างไม่รู้จบ
                      สนุกสนานและปลอดภัยภายใต้ระบบที่ได้มาตรฐานระดับสากล</span>
                  </div>
                  <img src="https://fs.cdnxn.com/landing-sbo55/Images/section-2-img2.png" className="section-2-img2" alt="img2" />
                </div>
              </div>
              <img src="https://fs.cdnxn.com/landing-sbo55/Images/ball.png" className="section-2-img3" alt="img2" />
              <img src="https://fs.cdnxn.com/landing-sbo55/Images/ball-mb.png" className="section-2-img3-mb" alt="img2" />
            </div>
            <div id="section-3" className="section-3">
              <div className="section-3-text-promotion">
                <div className="text-center">
                  <span className="section-text-head1">promotion</span><br />
                  <span className="section-text-head2">โปรโมชั่นแนะนำ</span>
                </div>
              </div>
              <div className="section-3-promotion">
                <div className="promotion-banner container-section">
                  <div className="promotion-banner">
                    <Splide
                      className="custom-slider"
                      options={{
                        rewind: true,
                        gap: "10px",
                        type: "loop",
                        autoplay: true,
                        interval: 3000,
                        arrows: true,
                        pauseOnHover: false,
                        perPage: 2,
                        focus: 'center',
                        breakpoints: {
                          900: {
                            perPage: 1,
                          },
                        },
                      }}
                    >
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="https://fs.cdnxn.com/landing-sbo55/Images/Pro1.png" alt="" className="slider-image" />
                        </a>
                      </SplideSlide>
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="https://fs.cdnxn.com/landing-sbo55/Images/Pro2.png" alt="" className="slider-image" />
                        </a>
                      </SplideSlide>
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="https://fs.cdnxn.com/landing-sbo55/Images/Pro3.jpg" alt="" className="slider-image" />
                        </a>
                      </SplideSlide>
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="https://fs.cdnxn.com/landing-sbo55/Images/Pro4.png" alt="" className="slider-image" />
                        </a>
                      </SplideSlide>
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="https://fs.cdnxn.com/landing-sbo55/Images/Pro5.png" alt="" className="slider-image" />
                        </a>
                      </SplideSlide>
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="/Images/Pro6.png" alt="" className="slider-image" />
                        </a>
                      </SplideSlide>
                    </Splide>
                  </div>
                  <div className="promotion-banner-mini">
                    <Splide
                      className="custom-slider"
                      options={{
                        rewind: true,
                        gap: "10px",
                        type: "loop",
                        autoplay: true,
                        interval: 3000,
                        arrows: false,
                        pauseOnHover: false,
                        perPage: 2,
                        focus: 'left',
                      }}
                    >
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="https://fs.cdnxn.com/landing-sbo55/Images/Pro1.png" alt="" className="w-100" />
                        </a>
                      </SplideSlide>
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="https://fs.cdnxn.com/landing-sbo55/Images/Pro2.png" alt="" className="w-100" />
                        </a>
                      </SplideSlide>
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="https://fs.cdnxn.com/landing-sbo55/Images/Pro3.jpg" alt="" className="w-100" />
                        </a>
                      </SplideSlide>
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="https://fs.cdnxn.com/landing-sbo55/Images/Pro4.png" alt="" className="w-100" />
                        </a>
                      </SplideSlide>
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="https://fs.cdnxn.com/landing-sbo55/Images/Pro5.png" alt="" className="w-100" />
                        </a>
                      </SplideSlide>
                      <SplideSlide className="custom-slider">
                        <a href={linkLogin}>
                          <img src="/Images/Pro6.png" alt="" className="w-100" />
                        </a>
                      </SplideSlide>
                    </Splide>
                  </div>
                </div>
              </div>
            </div>
            <div id="section-4" className="section-4">
              <div className="section-4-text-promotion">
                <div className="text-center">
                  <span className="section-text-head1">Providers</span><br />
                  <span className="section-text-head2">ผู้ให้บริการค่ายเกมส์</span>
                </div>
              </div>
              <div className="games-box container-section">
                <div className="games-category">
                  {gameCategories.map((category) => (
                    <div className="box-category-bg" key={category}>
                      <div
                        className={`category ${selectedCategory === category ? 'active' : ''}`}
                        onClick={() => setSelectedCategory(category)}
                      >
                        <div className={`category-${category}`}></div>
                        <span>{category === 'game' ? 'ยอดนิยม' : category === 'sport' ? 'กีฬา' : category === 'casino' ? 'คาสิโน' : category === 'slot' ? 'สล็อต' : 'หวย'}</span>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="games-list">
                  {displayedGames.map((image, index) => (
                    <a href={linkLogin} key={index}>
                      <div className="icon-games">
                        <img src={image} alt={selectedCategory} />
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="section-5">
              <div className="section-5-box container-section">
                <img src="https://fs.cdnxn.com/landing-sbo55/Images/logo.png" className="section-5-logo" alt="logo" />
                <span>SBO55 ศูนย์รวมเว็บพนันออนไลน์ทุกชนิด ฝากถอนด้วยระบบออโต้ทำรายการไม่กี่วินาที พร้อมรูปแบบการเล่นง่าย<br />
                  สมัครง่ายในไม่กี่ขั้นตอน บริการด้วยระบบออนไลน์มาตรฐานสากล เท่าไหร่ก็จ่าย</span>
                <div className="underline"></div>
                <div className="box-bank">
                  <span>PAYMENTS ACCEPTED</span>
                  <div className="icon-bank">
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/1.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/2.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/3.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/4.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/5.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/6.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/7.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/8.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/9.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/10.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/11.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/12.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/13.png" alt="icon bank" />
                    <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/14.png" alt="icon bank" />
                  </div>
                  <img src="https://fs.cdnxn.com/landing-sbo55/Images/icon-bank/payment-set.png" className="payment-set" alt="icon bank" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
